import api from "@/api/api-v1-config";
import sellbriteApi from "@/api/sellbrite-api-config";

export async function getPurchaseItemsByOrderIdAxios(orderId) {
  return await api.get(`pohistory/${orderId}`);
}
export async function createPurchaseItemAxios(purchaseitem) {
  return await api.post(
    `pohistory/${purchaseitem.purchaseOrderId}`,
    purchaseitem
  );
}
export async function fetchOrderDataByInventoryIdAxios(inventoryId) {
  return await api.get(`pohistory/summary/${inventoryId}`);
}
export async function fetchOrderDetailsByInventoryIdAxios(inventoryId) {
  return await api.get(`pohistory/details/${inventoryId}`);
}
export async function deletePurchaseItemAxios(purchaseitemId) {
  return await api.delete(`pohistory/item/${purchaseitemId}`);
}
export async function updatePurchaseItemAxios(purchaseitem) {
  return await api.put(`pohistory/item/${purchaseitem.id}`, purchaseitem);
}

export async function getSellbriteInventoryBySkuAxios(sku) {
  return await sellbriteApi.get(`inventory/?sku=${sku}`);
}
export async function updateSellbriteInventoryBySkuAxios(payload) {
  //console.log(payload);
  return await sellbriteApi.patch(`inventory`, payload);
}
