import * as types from "./types";
import {
  getAllSuppliersAxios,
  deleteSupplierByIdAxios,
  createSupplierAxios,
  updateSupplierAxios,
} from "@/store/supplier/services";

export async function getSuppliersListAction({ commit }) {
  commit(types.LOADING_SUPPLIERS, true);

  try {
    const { data } = await getAllSuppliersAxios();
    commit(types.GET_SUPPLIERS_LIST, data);
  } catch (e) {
    //alert(e);
    console.log(e);
  }

  commit(types.LOADING_SUPPLIERS, false);
}

export async function deleteSupplierByIdAction({ commit }, payload) {
  commit(types.LOADING_SUPPLIERS, true);

  try {
    await deleteSupplierByIdAxios(payload);
    commit(types.DEL_SUPPLIER_BY_ID, payload);
  } catch (e) {
    console.log(e);
  }

  commit(types.LOADING_SUPPLIERS, false);
}

export async function createSupplierAction({ commit }, payload) {
  commit(types.LOADING_SUPPLIERS, true);

  try {
    //console.log("create supplier: " + JSON.stringify(payload));
    const { data } = await createSupplierAxios(payload);
    payload.id = data.id;
    commit(types.CREATE_SUPPLIER, payload);
  } catch (e) {
    console.log(e);
  }

  commit(types.LOADING_SUPPLIERS, false);
}

export async function updateSupplierAction({ commit }, payload) {
  commit(types.LOADING_SUPPLIERS, true);

  try {
    //console.log("update supplier: " + JSON.stringify(payload));
    await updateSupplierAxios(payload);
    commit(types.UPDATE_SUPPLIER, payload);
  } catch (e) {
    console.log(e);
  }

  commit(types.LOADING_SUPPLIERS, false);
}
