<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="900px">
      <template v-slot:activator="{ on, attrs }">
        <div style="width: 90%">
          <v-btn
            style="margin-top: 1rem"
            color="light-blue"
            class="ml-2 white--text"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-plus</v-icon>
            Add New Item
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Search and Add Order Items</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="headers"
              :items="sp_inventories"
              :search="search"
              height="530px"
              fixed-header
              :footer-props="{
                'items-per-page-text': 'Items per page',
                'items-per-page-options': [10, 25, 50, -1],
              }"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <div class="d-flex" style="width: 100%">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      dense
                      outlined
                      single-line
                      hide-details
                    ></v-text-field>
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:[`item.history`]="{ item }">
                <ShowPurchaseHistory :bodyRequest="item" />
              </template>
              <template v-slot:[`item.order`]="{ item }">
                <AddSinglePurchaseItemForm
                  :bodyRequest="item"
                  :purchaseorderId="purchaseorderId"
                />
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import AddSinglePurchaseItemForm from "./AddSinglePurchaseItemForm.vue";
import ShowPurchaseHistory from "./ShowPurchaseHistory.vue";

export default {
  name: "AddOrderItemForm",
  components: {
    AddSinglePurchaseItemForm,
    ShowPurchaseHistory,
  },
  computed: {
    ...mapGetters("inventoryModule", { sp_inventories: "sp_inventories" }),
  },
  props: {
    purchaseorderId: Number,
  },
  data: () => ({
    search: "",
    dialog: false,
    headers: [
      { text: "SKU", value: "sku", sortable: true },
      { text: "Name", value: "name", sortable: true },
      { text: "Alert Level", value: "alertLevel", sortable: false },
      { text: "Units per Case", value: "unitsPerCase", sortable: false },
      { text: "History", value: "history", sortable: false },
      { text: "Order", value: "order", sortable: false },
    ],
  }),
  mounted() {
    //console.log("purchaseorderId:" + this.purchaseorderId);
  },
  methods: {
    showPurchaseHistory(item) {
      console.log("showPurchaseHistory" + item);
    },
  },
};
</script>
