export const LOADING_PURCHASEITEMS = "LOADING_PURCHASEITEMS";
export const GET_PURCHASEITEMS_BY_ORDERID = "GET_PURCHASEITEMS_BY_ORDERID";
export const FETCH_ORDERDATA_BY_INVENTORYID = "FETCH_ORDERDATA_BY_INVENTORYID";
export const FETCH_ORDERDETAILS_BY_INVENTORYID =
  "FETCH_ORDERDETAILS_BY_INVENTORYID";
export const DEL_PURCHASEITEM_BY_ID = "DEL_PURCHASEITEM_BY_ID";
export const CREATE_PURCHASEITEM = "CREATE_PURCHASEITEM";
export const UPDATE_PURCHASEITEM = "UPDATE_PURCHASEITEM";
export const SERVER_ERROR = "SERVER_ERROR";
export const FETCH_SELLBRITE_INVENTORY_BY_SKU =
  "FETCH_SELLBRITE_INVENTORY_BY_SKU";
