import api from "@/api/api-v1-config";
export async function getAllSuppliersAxios() {
  return await api.get(`suppliers`);
}

export async function deleteSupplierByIdAxios(id) {
  return await api.delete(`suppliers/` + id);
}

export async function createSupplierAxios(supplier) {
  return await api.post(`suppliers`, supplier);
}

export async function updateSupplierAxios(supplier) {
  return await api.put(`suppliers/${supplier.id}`, supplier);
}
