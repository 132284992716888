<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="green"
          class="mr-3"
          v-bind="attrs"
          v-on="on"
          @click="fetchOrderData"
        >
          mdi-archive-clock-outline
        </v-icon>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline"
            >[{{ bodyRequest.sku }}] {{ bodyRequest.name }}</span
          >
        </v-card-title>
        <v-card-subtitle class="mt-1"
          ><span class="headline indigo--text"
            >Total Due: {{ ordersummary.totalDue }} | On Hand:
          </span>
          <span
            class="headline indigo--text"
            v-for="item in inventory_data"
            :key="item.sku"
          >
            {{ item.on_hand }}
          </span>
        </v-card-subtitle>
        <v-card-text>
          <div class="font-weight-bold ml-8 mb-2">Order History</div>
          <v-timeline align-top dense>
            <v-timeline-item small v-for="item in orderdetails" :key="item.id">
              <div>
                <div class="font-weight-normal">
                  <strong>{{ item.notes }}</strong> (Updated at:
                  {{ formatDate(item.dateUpdated) }})
                </div>
                <div>
                  Ordered: {{ item.ordered }}, Received: {{ item.received }},
                  Due: {{ item.due }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "@/helper/tools";

export default {
  name: "ShowPurchaseHistory",
  props: {
    bodyRequest: {
      type: Object,
      required: true,
      default: () => ({
        sku: "",
        name: "",
        id: 0,
      }),
    },
  },
  data: () => ({
    dialog: false,
    totalDue: 1,
  }),
  methods: {
    ...mapActions("purchaseitemModule", [
      "fetchOrderDataByInventoryIdAction",
      "fetchOrderDetailsByInventoryIdAction",
      "getSellbriteInventoryBySkuAction",
    ]),
    fetchOrderData() {
      this.fetchOrderDataByInventoryIdAction(this.bodyRequest.id);
      this.fetchOrderDetailsByInventoryIdAction(this.bodyRequest.id);
      this.getSellbriteInventoryBySkuAction(this.bodyRequest.sku);
    },
    formatDate,
  },
  computed: {
    ...mapGetters("purchaseitemModule", {
      ordersummary: "ordersummary",
      orderdetails: "orderdetails",
      inventory_data: "sellbrite_inventory_data",
      loading: "loading",
    }),
  },
};
</script>
