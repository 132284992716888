import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import supplierModule from "./supplier";
import authModule from "./auth";
import inventoryModule from "./inventory";
import purchaseorderModule from "./purchaseorder";
import purchaseitemModule from "./purchaseitems";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
const plugins = debug ? [createLogger({})] : [];

export default new Vuex.Store({
  modules: {
    supplierModule,
    authModule,
    inventoryModule,
    purchaseorderModule,
    purchaseitemModule,
  },
  plugins,
});
