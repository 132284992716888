var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.initialize}},'v-icon',attrs,false),on),[_vm._v("mdi-clipboard-edit-outline")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edit Purchase Order Items")])]),_c('v-card-subtitle',{staticClass:"subtitle-1"},[_vm._v(" Total lines: "+_vm._s(_vm.purchaseitems.length)+" | Total needed: "+_vm._s(_vm.purchaseitems.reduce(function (n, ref) {
            var ordered = ref.ordered;

            return +n + +ordered;
}, 0))+" | Total received: "+_vm._s(_vm.purchaseitems.reduce(function (n, ref) {
            var received = ref.received;

            return +n + +received;
}, 0))+" | Total due: "+_vm._s(_vm.purchaseitems.reduce(function (n, ref) {
            var ordered = ref.ordered;

            return +n + +ordered;
}, 0) - _vm.purchaseitems.reduce(function (n, ref) {
            var received = ref.received;

            return +n + +received;
}, 0))+" ")]),_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.purchaseitems,"search":_vm.search,"fixed-header":"","height":"530px","footer-props":{
              'items-per-page-text': 'Items per page',
              'items-per-page-options': [10, 25, 50, -1],
            }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('AddOrderItemsForm',{attrs:{"purchaseorderId":_vm.bodyRequest.id}})],1)])]},proxy:true},{key:"item.sku",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getInventorySku(_vm.sp_inventories, item.inventoryId)))])]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getInventoryName(_vm.sp_inventories, item.inventoryId)))])]}},{key:"item.ordered",fn:function(ref){
            var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('v-text-field',{attrs:{"hide-details":true,"dense":"","single-line":""},model:{value:(_vm.editedItem.ordered),callback:function ($$v) {_vm.$set(_vm.editedItem, "ordered", $$v)},expression:"editedItem.ordered"}}):_c('span',[_vm._v(_vm._s(item.ordered))])]}},{key:"item.received",fn:function(ref){
            var item = ref.item;
return [(item.id === _vm.editedItem.id && _vm.orderstatus === 1)?_c('v-text-field',{attrs:{"hide-details":true,"dense":"","single-line":""},model:{value:(_vm.editedItem.received),callback:function ($$v) {_vm.$set(_vm.editedItem, "received", $$v)},expression:"editedItem.received"}}):_c('span',[_vm._v(_vm._s(item.received))])]}},{key:"item.due",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ordered - item.received))])]}},{key:"item.dateUpdated",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dateUpdated)))])]}},{key:"item.history",fn:function(ref){
            var item = ref.item;
return [_c('ShowPurchaseHistory',{attrs:{"bodyRequest":_vm.getInventoryById(_vm.sp_inventories, item.inventoryId)}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red"},on:{"click":_vm.close}},[_vm._v(" mdi-window-close ")]),_c('v-icon',{attrs:{"color":"green"},on:{"click":_vm.save}},[_vm._v(" mdi-content-save ")])],1):_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('v-divider',{attrs:{"inset":""}})],1)],1),_c('v-card-actions',[(_vm.serverError)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage))]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"blue darken-1"},on:{"click":function($event){_vm.cancelDialog();
              _vm.checkAndSetPOStatus();}}},[_vm._v("Close")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }