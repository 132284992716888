var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticStyle:{"width":"90%"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 white--text",staticStyle:{"margin-top":"1rem"},attrs:{"color":"light-blue","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add New Item ")],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Search and Add Order Items")])]),_c('v-card-text',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sp_inventories,"search":_vm.search,"height":"530px","fixed-header":"","footer-props":{
              'items-per-page-text': 'Items per page',
              'items-per-page-options': [10, 25, 50, -1],
            }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.history",fn:function(ref){
            var item = ref.item;
return [_c('ShowPurchaseHistory',{attrs:{"bodyRequest":item}})]}},{key:"item.order",fn:function(ref){
            var item = ref.item;
return [_c('AddSinglePurchaseItemForm',{attrs:{"bodyRequest":item,"purchaseorderId":_vm.purchaseorderId}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }