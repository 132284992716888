import * as types from "./types";
const mutations = {
  [types.GET_PURCHASEITEMS_BY_ORDERID](state, purchaseitems) {
    state.purchaseitems = purchaseitems;
  },
  [types.FETCH_ORDERDATA_BY_INVENTORYID](state, ordersummary) {
    state.ordersummary = ordersummary;
  },
  [types.FETCH_ORDERDETAILS_BY_INVENTORYID](state, orderdetails) {
    state.orderdetails = orderdetails;
  },
  [types.FETCH_SELLBRITE_INVENTORY_BY_SKU](state, inventory_data) {
    state.sellbrite_inventory_data = inventory_data;
  },
  [types.LOADING_PURCHASEITEMS](state, value) {
    state.loading = value;
  },
  [types.CREATE_PURCHASEITEM](state, purchaseitem) {
    state.purchaseitems.push(purchaseitem);
  },
  [types.UPDATE_PURCHASEITEM](state, purchaseitem) {
    const idx = state.purchaseitems.indexOf(purchaseitem);
    state.purchaseitems[idx] = purchaseitem;
  },
  [types.DEL_PURCHASEITEM_BY_ID](state, purchaseitem) {
    const idx = state.purchaseitems.findIndex((s) => s.id === purchaseitem.id);
    state.purchaseitems.splice(idx, 1);
  },
  [types.SERVER_ERROR](state, message = "") {
    state.serverError = message == "" ? false : true;
    state.errorMessage = message;
  },
};
export default mutations;
