import * as types from "./types";
import {
  getAllInventoriesAxios,
  getInventoriesBySupplierAxios,
  deleteInventoryByIdAxios,
  createInventoryAxios,
  updateInventoryAxios,
} from "@/store/inventory/services";

export async function getInventoriesListAction({ commit }, payload) {
  commit(types.LOADING_INVENTORIES, true);

  try {
    const searchTerm = payload.searchTerm;
    const curPage = payload.curPage;
    const pageSize = payload.pageSize;
    const { data } = await getAllInventoriesAxios(
      searchTerm,
      curPage,
      pageSize
    );
    //console.log("response: " + JSON.stringify(data));
    commit(types.GET_INVENTORIES_LIST, data);
  } catch (e) {
    console.log(e);
  }
  commit(types.LOADING_INVENTORIES, false);
}

export async function getInventoriesBySupplierAction({ commit }, payload) {
  commit(types.LOADING_INVENTORIES, true);
  try {
    const { data } = await getInventoriesBySupplierAxios(payload);
    commit(types.GET_INVENTORIES_BY_SUPPLIER, data);
  } catch (e) {
    console.log(e);
  }
  commit(types.LOADING_INVENTORIES, false);
}

export async function deleteInventoryByIdAction({ commit }, payload) {
  commit(types.LOADING_INVENTORIES, true);

  try {
    await deleteInventoryByIdAxios(payload);
    commit(types.DEL_INVENTORY_BY_ID, payload);
  } catch (e) {
    console.log(e);
  }

  commit(types.LOADING_INVENTORIES, false);
}

export async function createInventoryAction({ commit }, payload) {
  commit(types.LOADING_INVENTORIES, true);
  try {
    const { data } = await createInventoryAxios(payload);
    payload.id = data.id;
    commit(types.CREATE_INVENTORY, payload);
  } catch (e) {
    console.log(e);
  }
  commit(types.LOADING_INVENTORIES, false);
}

export async function updateInventoryAction({ commit }, payload) {
  commit(types.LOADING_INVENTORIES, true);
  try {
    await updateInventoryAxios(payload);
    commit(types.UPDATE_INVENTORY, payload);
  } catch (e) {
    console.log(e);
  }
  commit(types.LOADING_INVENTORIES, false);
}
