import axios from "axios";

const debug = process.env.NODE_ENV !== "production";

const sellbriteApiURL = debug
  ? "http://localhost:8010/proxy"
  : "https://po.ukhp.co/proxy";

let sellbriteApi = axios.create({
  baseURL: sellbriteApiURL,
  auth: {
    username: "5ff7195a-25e2-4a7e-b278-75b33efe9dcb",
    password: "f1f57f228cae892ed6580801d274866a",
  },
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

export default sellbriteApi;
