import * as types from "./types";
const mutations = {
  [types.GET_PURCHASEORDERS_LIST](state, purchaseorders) {
    state.purchaseorders = purchaseorders;
  },
  [types.LOADING_PURCHASEORDERS](state, value) {
    state.loading = value;
  },
  [types.UPDATE_PURCHASEORDER](state, purchaseorder) {
    const idx = state.purchaseorders.findIndex(
      (s) => s.id === purchaseorder.id
    );
    state.purchaseorders[idx] = purchaseorder;
  },
  [types.REMOVE_PURCHASEORDER_FROM_LIST](state, purchaseorder) {
    const idx = state.purchaseorders.findIndex(
      (s) => s.id === purchaseorder.id
    );
    state.purchaseorders.splice(idx, 1);
  },
  [types.DEL_PURCHASEORDER_BY_ID](state, purchaseorder) {
    const idx = state.purchaseorders.findIndex(
      (s) => s.id === purchaseorder.id
    );
    state.purchaseorders[idx] = purchaseorder;
  },
};
export default mutations;
