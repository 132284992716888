<template>
  <v-app-bar app color="primary" dark>
    <div class="d-flex align-center">
      <router-link to="/" class="menu">
        <v-img
          alt="Vuetify logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
      /></router-link>
      <div>
        <span v-if="isAuthenticated">Logged in as {{ username }}</span>
      </div>
    </div>

    <v-spacer></v-spacer>

    <div>
      <v-btn color="primary" outlined :to="{ path: '/' }">
        <span class="menu">Home</span>
      </v-btn>
      <v-btn color="primary" outlined :to="{ path: '/purchase-orders' }">
        <span class="menu">Purchase Orders</span>
      </v-btn>
      <router-link to="/inventories">
        <v-btn color="primary" outlined>
          <span class="menu">Inventories</span>
        </v-btn>
      </router-link>
      <router-link to="/suppliers">
        <v-btn color="primary" outlined>
          <span class="menu">Suppliers</span>
        </v-btn>
      </router-link>
      <v-btn
        v-if="isAuthenticated"
        color="primary"
        outlined
        @click="handleLogout()"
      >
        <span class="menu">Logout</span>
      </v-btn>
      <v-btn v-else color="primary" outlined :to="{ path: '/login' }">
        <span class="menu">Login</span>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { logOut } from "@/auth/auth.service";

export default {
  name: "NavigationBar",
  computed: {
    ...mapGetters("authModule", {
      isAuthenticated: "isAuthenticated",
      username: "username",
    }),
  },
  methods: {
    ...mapActions("authModule", ["useLocalStorageTokenToSignInAction"]),

    localstorageLogin() {
      this.useLocalStorageTokenToSignInAction().then();
    },

    handleLogout() {
      logOut();
    },
  },
  mounted() {
    this.localstorageLogin();
  },
};
</script>

<style scoped>
.menu {
  color: white;
  text-decoration: none;
}
</style>
