<template>
  <div class="home fill-height d-flex justify-center align-center">
    <h1>Purchase Orders Homepage</h1>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
