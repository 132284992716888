const state = {
  purchaseitems: [],
  loading: false,
  ordersummary: {},
  sellbrite_inventory_data: {},
  orderdetails: [],
  serverError: false,
  errorMessage: "",
};

export default state;
