<template>
  <v-container>
    <div>
      <div class="text-h2 my-4">Partial Delivered Orders</div>
    </div>
    <div class="v-picker--full-width d-flex justify-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-simple-table>
      <template v-slot:default>
        <col style="width: 5%" />
        <col style="width: 30%" />
        <col style="width: 15%" />
        <col style="width: 15%" />
        <col style="width: 15%" />
        <col style="width: 7%" />
        <col style="width: 8%" />
        <col style="width: 5%" />
        <thead>
          <tr>
            <th class="text-left">Id</th>
            <th class="text-left">Notes</th>
            <th class="text-left">Supplier</th>
            <th class="text-left">Created At</th>
            <th class="text-left">Last Updated</th>
            <th class="text-left">Edit PO</th>
            <th class="text-left">Edit Items</th>
            <th class="text-left">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in purchaseorders" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.notes }}</td>
            <td>{{ getSupplierNameById(item.supplierId) }}</td>
            <td>{{ formatDate(item.dateCreated) }}</td>
            <td>{{ formatDate(item.dateModified) }}</td>
            <td><UpdatePurchaseOrderForm :bodyRequest="item" /></td>
            <td>
              <UpdatePurchaseItemsForm :bodyRequest="item" :orderstatus="1" />
            </td>
            <td>
              <v-icon @click="deletePurchaseOrderById(item)"
                >mdi-delete-outline</v-icon
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "@/helper/tools";
import UpdatePurchaseOrderForm from "@/components/UpdatePurchaseOrderForm.vue";
import UpdatePurchaseItemsForm from "@/components/UpdatePurchaseItemsForm.vue";

export default {
  name: "DraftOrders",
  components: {
    UpdatePurchaseOrderForm,
    UpdatePurchaseItemsForm,
  },
  mounted() {
    this.fetchPurchaseOrders();
    this.fetchSuppliers();
  },
  methods: {
    ...mapActions("purchaseorderModule", [
      "getPurchaseOrdersListAction",
      "deletePurchaseOrderByIdAction",
      "removePurchaseOrderFromListAction",
    ]),
    ...mapActions("supplierModule", ["getSuppliersListAction"]),
    fetchPurchaseOrders() {
      this.getPurchaseOrdersListAction(2);
    },
    fetchSuppliers() {
      this.getSuppliersListAction();
    },
    getSupplierNameById(id) {
      //console.log(JSON.stringify(this.suppliers));
      var supplier = this.suppliers.find((e) => e.id === id);
      if (supplier) return supplier.supplierName;
      else return "";
    },
    deletePurchaseOrderById(purchaseorder) {
      const confirmed = confirm(
        "Are you sure you want to cancel this purchase order \n\n " +
          purchaseorder.notes
      );
      if (!confirmed) return;
      this.deletePurchaseOrderByIdAction(purchaseorder);
      this.removePurchaseOrderFromListAction(purchaseorder);
    },
    formatDate,
  },

  computed: {
    ...mapGetters("purchaseorderModule", {
      purchaseorders: "purchaseorders",
      loading: "loading",
    }),
    ...mapGetters("supplierModule", {
      suppliers: "suppliers",
    }),
  },
};
</script>
