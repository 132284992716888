import axios from "axios";
import { interceptorsInit } from "@/api/interceptors";

const debug = process.env.NODE_ENV !== "production";
const baseURL = debug
  ? "http://localhost:5000/api/v1/"
  : "https://po.ukhp.co/api/v1";

let api = axios.create({ baseURL });
api = interceptorsInit(api);

export default api;
