import api from "@/api/api-v1-config";
export async function getAllInventoriesAxios(searchTerm, curPage, pageSize) {
  return await api.get(
    `inventories/?searchTerm=${searchTerm}&curPage=${curPage}&pageSize=${pageSize}`
  );
}
export async function getInventoriesBySupplierAxios(supplierid) {
  return await api.get(`inventories/supplier/${supplierid}`);
}
export async function deleteInventoryByIdAxios(id) {
  return await api.delete(`inventories/` + id);
}
export async function createInventoryAxios(inventory) {
  console.log(inventory);
  return await api.post(`inventories`, inventory);
}
export async function updateInventoryAxios(inventory) {
  return await api.put(`inventories/${inventory.id}`, inventory);
}
