const getters = {
  id: (state) => {
    return state.signInState.email;
  },
  fullname: (state) => {
    return state.signInState.firstName + " " + state.signInState.lastName;
  },
  username: (state) => {
    return state.signInState.username;
  },
  isAuthenticated: (state) => {
    return state.signInState.token;
  },
};
export default getters;
