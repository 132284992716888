import * as types from "./types";
const mutations = {
  [types.GET_SUPPLIERS_LIST](state, suppliers) {
    state.suppliers = suppliers;
  },
  [types.LOADING_SUPPLIERS](state, value) {
    state.loading = value;
  },
  [types.DEL_SUPPLIER_BY_ID](state, id) {
    state.suppliers = state.suppliers.filter((s) => s.id !== id);
  },
  [types.CREATE_SUPPLIER](state, supplier) {
    state.suppliers.push(supplier);
  },
  [types.UPDATE_SUPPLIER](state, supplier) {
    const idx = state.suppliers.findIndex((s) => s.id === supplier.id);
    state.suppliers[idx] = supplier;
  },
};
export default mutations;
