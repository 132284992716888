const state = {
  signInState: {
    id: "",
    firstName: "",
    lastName: "",
    username: "",
    exp: Date.now(),
    token: null,
  },
};

export default state;
