import api from "@/api/api-v1-config";
import * as jwt from "jsonwebtoken";

const key = "token";

export function getToken() {
  return localStorage.getItem(key);
}

export function logOut() {
  localStorage.clear();
  window.location = "/login";
}

export async function loginUserAxios(login) {
  return await api.post("users/authenticate", login);
}

export function isTokenFromLocalStorageValid() {
  const token = localStorage.getItem(key);
  if (!token) {
    return false;
  }

  const decoded = jwt.decode(token);
  const expiresAt = decoded.exp * 1000;
  const dateNow = Date.now();
  return dateNow <= expiresAt;
}

export function getUsernameFromToken() {
  const token = localStorage.getItem(key);
  //console.log("getUsernameFromToken: " + token);
  if (!token) return false;
  const decoded = jwt.decode(token);
  //console.log("decoded token: " + JSON.stringify(decoded));
  return decoded.username;
}
