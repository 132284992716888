export function formatDate(DateString) {
  var d = new Date(DateString),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    min = "" + d.getMinutes();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (min.length < 2) min = "0" + min;

  var ymd = [year, month, day].join("-");
  var time = [hour, min].join(":");

  return [ymd, time].join(" ");
}

export function getSupplierNameById(suppliers, id) {
  if (suppliers.length === 0) return "";
  if (id <= 0) return "";
  const supplier = suppliers.find((s) => {
    return s.id === id;
  });
  if (supplier) return supplier.supplierName;
  else return "";
}

export function getInventoryById(inventories, id) {
  if (inventories.length === 0 || id <= 0) return;
  const inventory = inventories.find((i) => {
    return i.id === id;
  });
  return inventory;
}

export function getInventorySku(inventories, id) {
  const inv = getInventoryById(inventories, id);
  return inv ? inv.sku : "";
}

export function getInventoryName(inventories, id) {
  const inv = getInventoryById(inventories, id);
  return inv ? inv.name : "";
}
