const getters = {
  purchaseitems: (state) => state.purchaseitems,
  loading: (state) => state.loading,
  ordersummary: (state) => state.ordersummary,
  orderdetails: (state) => state.orderdetails,
  sellbrite_inventory_data: (state) => state.sellbrite_inventory_data,
  serverError: (state) => state.serverError,
  errorMessage: (state) => state.errorMessage,
};
export default getters;
