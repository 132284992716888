<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1200px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" @click="initialize"
          >mdi-clipboard-edit-outline</v-icon
        >
      </template>
      <v-card>
        <form @submit.prevent="onSubmit">
          <v-card-title>
            <span class="headline">Edit Purchase Order Items</span>
          </v-card-title>
          <v-card-subtitle class="subtitle-1">
            Total lines: {{ purchaseitems.length }} | Total needed:
            {{ purchaseitems.reduce((n, { ordered }) => +n + +ordered, 0) }}
            | Total received:
            {{ purchaseitems.reduce((n, { received }) => +n + +received, 0) }} |
            Total due:
            {{
              purchaseitems.reduce((n, { ordered }) => +n + +ordered, 0) -
              purchaseitems.reduce((n, { received }) => +n + +received, 0)
            }}
          </v-card-subtitle>
          <v-container>
            <v-data-table
              :headers="headers"
              :items="purchaseitems"
              :search="search"
              class="elevation-1"
              fixed-header
              height="530px"
              :footer-props="{
                'items-per-page-text': 'Items per page',
                'items-per-page-options': [10, 25, 50, -1],
              }"
            >
              <v-divider inset></v-divider>
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <div class="d-flex" style="width: 100%">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      dense
                      outlined
                      single-line
                      hide-details
                    ></v-text-field>
                    <AddOrderItemsForm :purchaseorderId="bodyRequest.id" />
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:[`item.sku`]="{ item }">
                <span>{{
                  getInventorySku(sp_inventories, item.inventoryId)
                }}</span>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <span>{{
                  getInventoryName(sp_inventories, item.inventoryId)
                }}</span>
              </template>
              <template v-slot:[`item.ordered`]="{ item }">
                <v-text-field
                  v-model="editedItem.ordered"
                  :hide-details="true"
                  dense
                  single-line
                  v-if="item.id === editedItem.id"
                ></v-text-field>
                <span v-else>{{ item.ordered }}</span>
              </template>
              <template v-slot:[`item.received`]="{ item }">
                <v-text-field
                  v-model="editedItem.received"
                  :hide-details="true"
                  dense
                  single-line
                  v-if="item.id === editedItem.id && orderstatus === 1"
                ></v-text-field>
                <span v-else>{{ item.received }}</span>
              </template>
              <template v-slot:[`item.due`]="{ item }">
                <span>{{ item.ordered - item.received }}</span>
              </template>
              <template v-slot:[`item.dateUpdated`]="{ item }">
                <span>{{ formatDate(item.dateUpdated) }}</span>
              </template>
              <template v-slot:[`item.history`]="{ item }">
                <ShowPurchaseHistory
                  :bodyRequest="
                    getInventoryById(sp_inventories, item.inventoryId)
                  "
                />
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div v-if="item.id === editedItem.id">
                  <v-icon color="red" class="mr-3" @click="close">
                    mdi-window-close
                  </v-icon>
                  <v-icon color="green" @click="save">
                    mdi-content-save
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon color="green" class="mr-3" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon color="red" @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </div>
              </template>
            </v-data-table>
          </v-container>
          <v-card-actions>
            <v-alert dense outlined type="error" v-if="serverError">
              {{ errorMessage }}</v-alert
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="blue darken-1"
              @click="
                cancelDialog();
                checkAndSetPOStatus();
              "
              >Close</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  getInventorySku,
  getInventoryName,
  getInventoryById,
  formatDate,
} from "@/helper/tools";
import AddOrderItemsForm from "./AddOrderItemsForm.vue";
import ShowPurchaseHistory from "./ShowPurchaseHistory.vue";

export default {
  name: "UpdatePurchaseItemsForm",
  components: {
    AddOrderItemsForm,
    ShowPurchaseHistory,
  },
  props: {
    bodyRequest: {
      type: Object,
      required: true,
      default: () => ({
        id: 0,
        status: 0,
        supplierId: 0,
      }),
    },
    orderstatus: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    dialog: false,
    search: "",
    editedIndex: -1,
    editedItem: {
      id: 0,
      ordered: 0,
      received: 0,
    },
    defaultItem: {
      id: 0,
      ordered: 0,
      received: 0,
    },
    headers: [
      {
        text: "SKU",
        value: "sku",
        sortable: false,
      },
      {
        text: "Name",
        value: "name",
        sortable: false,
      },
      {
        text: "Needed",
        value: "ordered",
        sortable: false,
      },
      {
        text: "Received",
        value: "received",
        sortable: false,
      },
      {
        text: "Due",
        value: "due",
        sortable: false,
      },
      {
        text: "Last Updated",
        value: "dateUpdated",
        sortable: false,
      },
      { text: "History", value: "history", sortable: false },
      { text: "Actions", value: "actions", sortable: false, width: "100px" },
    ],
  }),
  methods: {
    ...mapActions("inventoryModule", ["getInventoriesBySupplierAction"]),
    ...mapActions("purchaseitemModule", [
      "getPurchaseItemsByOrderIdAction",
      "deletePurchaseItemAction",
      "resetServerErrorAction",
      "updatePurchaseItemAction",
      "getSellbriteInventoryBySkuAction",
      "updateSellbriteInventoryBySkuAction",
    ]),
    ...mapActions("purchaseorderModule", ["updatePurchaseOrderAction"]),
    initialize() {
      this.getPurchaseItemsByOrderIdAction(this.bodyRequest.id);
      this.getInventoriesBySupplierAction(this.bodyRequest.supplierId);
    },
    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 200);
    },
    editItem(item) {
      this.editedIndex = this.purchaseitems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.getSellbriteInventoryBySkuAction(
        this.purchaseitems[this.editedIndex].sku
      );
    },
    deleteItem(item) {
      //console.log(item);
      const confirmed = confirm(
        "Are you sure you want to delete \n\n" +
          getInventoryName(this.sp_inventories, item.inventoryId)
      );
      if (!confirmed) return;
      this.deletePurchaseItemAction(item);
    },
    save() {
      if (this.editedIndex > -1) {
        if (parseInt(this.editedItem.ordered) <= 0)
          alert("Quantity needed should be greater than 0!");
        else if (
          parseInt(this.editedItem.ordered) < parseInt(this.editedItem.received)
        ) {
          alert("Quantity needed should be greater than received!");
        } else {
          const added =
            parseInt(this.editedItem.received) -
            this.purchaseitems[this.editedIndex].received;

          let on_hand = 0;
          if (this.inventory_data && this.inventory_data.length > 0) {
            on_hand = this.inventory_data[0]["on_hand"];
          }
          const on_hand_now = on_hand + added;

          Object.assign(this.purchaseitems[this.editedIndex], this.editedItem);
          this.updatePurchaseItemAction(this.purchaseitems[this.editedIndex]);

          if (added > 0) {
            let payload = { sku: this.editedItem.sku, on_hand: on_hand_now };
            this.updateSellbriteInventoryBySkuAction(payload);

            alert(
              `${this.editedItem.sku}'s current on hand: ${on_hand} \n has been updated to: ${on_hand_now}.`
            );
          }
        }
      }
      this.close();
    },
    cancelDialog() {
      this.resetServerErrorAction();
      this.dialog = false;
    },
    checkAndSetPOStatus() {
      let curStatus = this.bodyRequest.status;
      const totalNeeded = this.purchaseitems.reduce(
        (n, { ordered }) => +n + +ordered,
        0
      );
      const totalReceived = this.purchaseitems.reduce(
        (n, { received }) => +n + +received,
        0
      );
      if (totalReceived > 0) {
        if (totalReceived === totalNeeded) this.bodyRequest.status = 3;
        else if (totalReceived < totalNeeded) this.bodyRequest.status = 2;

        if (this.bodyRequest.status !== curStatus) {
          this.updatePurchaseOrderAction(this.bodyRequest);
        }
      }
    },
    getInventorySku,
    getInventoryName,
    getInventoryById,
    formatDate,
  },
  computed: {
    ...mapGetters("purchaseitemModule", {
      purchaseitems: "purchaseitems",
      serverError: "serverError",
      errorMessage: "errorMessage",
      inventory_data: "sellbrite_inventory_data",
    }),
    ...mapGetters("inventoryModule", { sp_inventories: "sp_inventories" }),
  },
};
</script>
