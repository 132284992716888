import * as types from "./types";
import {
  getAllPurchaseOrdersAxios,
  createPurchaseOrderAxios,
  updatePurchaseOrderAxios,
  deletePurchaseOrderAxios,
} from "@/store/purchaseorder/services";

export async function getPurchaseOrdersListAction({ commit }, status) {
  commit(types.LOADING_PURCHASEORDERS, true);

  try {
    const { data } = await getAllPurchaseOrdersAxios(status);
    commit(types.GET_PURCHASEORDERS_LIST, data);
  } catch (e) {
    console.log(e);
  }

  commit(types.LOADING_PURCHASEORDERS, false);
}

export async function createPurchaseOrderAction({ commit }, payload) {
  commit(types.LOADING_PURCHASEORDERS, true);

  try {
    const { data } = await createPurchaseOrderAxios(payload);
    payload.id = data.id;
    commit(types.CREATE_PURCHASEORDER, payload);
  } catch (e) {
    console.log(e);
  }

  commit(types.LOADING_PURCHASEORDERS, false);
}

export async function updatePurchaseOrderAction({ commit }, payload) {
  commit(types.LOADING_PURCHASEORDERS, true);
  try {
    await updatePurchaseOrderAxios(payload);
    commit(types.UPDATE_PURCHASEORDER, payload);
  } catch (e) {
    console.log(e);
  }
  commit(types.LOADING_PURCHASEORDERS, false);
}

export function removePurchaseOrderFromListAction({ commit }, payload) {
  commit(types.REMOVE_PURCHASEORDER_FROM_LIST, payload);
}

export async function deletePurchaseOrderByIdAction({ commit }, payload) {
  commit(types.LOADING_PURCHASEORDERS, true);
  try {
    await deletePurchaseOrderAxios(payload);
    payload.Status = 4; // set order status to cancelled
    commit(types.DEL_PURCHASEORDER_BY_ID, payload);
  } catch (e) {
    console.log(e);
  }
  commit(types.LOADING_PURCHASEORDERS, false);
}
