import * as types from "./types";
import {
  getPurchaseItemsByOrderIdAxios,
  fetchOrderDataByInventoryIdAxios,
  fetchOrderDetailsByInventoryIdAxios,
  createPurchaseItemAxios,
  updatePurchaseItemAxios,
  deletePurchaseItemAxios,
  getSellbriteInventoryBySkuAxios,
  updateSellbriteInventoryBySkuAxios,
} from "@/store/purchaseitems/services";

export async function getPurchaseItemsByOrderIdAction({ commit }, orderId) {
  commit(types.LOADING_PURCHASEITEMS, true);
  try {
    const { data } = await getPurchaseItemsByOrderIdAxios(orderId);
    commit(types.GET_PURCHASEITEMS_BY_ORDERID, data);
  } catch (e) {
    console.log(e);
  }
  commit(types.LOADING_PURCHASEITEMS, false);
}

export async function fetchOrderDataByInventoryIdAction(
  { commit },
  inventoryId
) {
  commit(types.LOADING_PURCHASEITEMS, true);
  try {
    const { data } = await fetchOrderDataByInventoryIdAxios(inventoryId);
    commit(types.FETCH_ORDERDATA_BY_INVENTORYID, data);
  } catch (e) {
    console.log(e);
  }
  commit(types.LOADING_PURCHASEITEMS, false);
}

export async function fetchOrderDetailsByInventoryIdAction(
  { commit },
  inventoryId
) {
  commit(types.LOADING_PURCHASEITEMS, true);
  try {
    const { data } = await fetchOrderDetailsByInventoryIdAxios(inventoryId);
    commit(types.FETCH_ORDERDETAILS_BY_INVENTORYID, data);
  } catch (e) {
    console.log(e);
  }
  commit(types.LOADING_PURCHASEITEMS, false);
}

export async function createPurchaseItemAction({ commit }, payload) {
  commit(types.LOADING_PURCHASEITEMS, true);
  try {
    const { data } = await createPurchaseItemAxios(payload);
    payload.id = data.id;
    payload.received = data.received;
    payload.dateUpdated = data.dateUpdated;
    commit(types.CREATE_PURCHASEITEM, payload);
  } catch (e) {
    commit(types.SERVER_ERROR, e.message);
    //console.log("error found: " + e.message);
  }
  commit(types.LOADING_PURCHASEITEMS, false);
}

export async function updatePurchaseItemAction({ commit }, payload) {
  commit(types.LOADING_PURCHASEITEMS, true);
  try {
    await updatePurchaseItemAxios(payload);
    payload.dateUpdated = Date.now();
    commit(types.UPDATE_PURCHASEITEM, payload);
  } catch (e) {
    commit(types.SERVER_ERROR, e.message);
    console.log("error found: " + e.message);
  }
  commit(types.LOADING_PURCHASEITEMS, false);
}

export async function resetServerErrorAction({ commit }) {
  commit(types.SERVER_ERROR, false);
}

export async function deletePurchaseItemAction({ commit }, payload) {
  commit(types.LOADING_PURCHASEITEMS, true);
  try {
    await deletePurchaseItemAxios(payload.id);
    commit(types.DEL_PURCHASEITEM_BY_ID, payload);
  } catch (e) {
    commit(types.SERVER_ERROR, e.message);
    console.log("error found: " + e.message);
  }
  commit(types.LOADING_PURCHASEITEMS, false);
}

export async function getSellbriteInventoryBySkuAction({ commit }, sku) {
  commit(types.LOADING_PURCHASEITEMS, true);
  try {
    const { data } = await getSellbriteInventoryBySkuAxios(sku);
    console.log(data);
    commit(types.FETCH_SELLBRITE_INVENTORY_BY_SKU, data);
  } catch (e) {
    commit(types.SERVER_ERROR, e.message);
    console.log("error found: " + e.message);
  }
  commit(types.LOADING_PURCHASEITEMS, false);
}

export async function updateSellbriteInventoryBySkuAction({ commit }, payload) {
  commit(types.LOADING_PURCHASEITEMS, true);
  let inventory = [];
  payload.warehouse_uuid = "fead8580-08ef-4f25-bfb7-66f0b2809bac";
  inventory.push(payload);
  let req = { inventory };
  try {
    await updateSellbriteInventoryBySkuAxios(req);
  } catch (e) {
    commit(types.SERVER_ERROR, e.message);
    console.log("error found: " + e.message);
  }
  commit(types.LOADING_PURCHASEITEMS, false);
}
