import * as types from "./types";
const mutations = {
  [types.GET_INVENTORIES_LIST](state, response) {
    state.inventories = response.data;
    state.totalPages = response.totalPages;
  },
  [types.GET_INVENTORIES_BY_SUPPLIER](state, inventories) {
    state.sp_inventories = inventories;
  },
  [types.LOADING_INVENTORIES](state, value) {
    state.loading = value;
  },
  [types.DEL_INVENTORY_BY_ID](state, id) {
    state.inventories = state.inventories.filter((s) => s.id !== id);
  },
  [types.CREATE_INVENTORY](state, inventory) {
    state.inventories.push(inventory);
  },
  [types.UPDATE_INVENTORY](state, inventory) {
    const idx = state.suppliers.findIndex((s) => s.id === inventory.id);
    state.inventories[idx] = inventory;
  },
};
export default mutations;
