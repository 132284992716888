import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import DraftOrders from "@/views/purchase-orders/DraftOrders.vue";
import OpenOrders from "@/views/purchase-orders/OpenOrders.vue";
import PartialDeliveredOrders from "@/views/purchase-orders/PartialDeliveredOrders.vue";
import CompletedOrders from "@/views/purchase-orders/CompletedOrders.vue";
import CancelledOrders from "@/views/purchase-orders/CancelledOrders.vue";
import { authGuard } from "@/auth/auth.guard";
import { isTokenFromLocalStorageValid } from "@/auth/auth.service";

Vue.use(VueRouter);
4;
const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/purchase-orders",
    component: () => import("@/views/purchase-orders/index.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: () => import("@/views/purchase-orders/PurchaseOrders.vue"),
      },
      { path: "draft-orders", component: DraftOrders },
      { path: "open-orders", component: OpenOrders },
      { path: "partial-delivered-orders", component: PartialDeliveredOrders },
      { path: "completed-orders", component: CompletedOrders },
      { path: "cancelled-orders", component: CancelledOrders },
    ],
  },
  {
    path: "/inventories",
    name: "Inventories",
    component: () => import("@/views/Inventories.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: () => import("@/views/Suppliers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/auth/views/Login.vue"),
    meta: {
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      const valid = isTokenFromLocalStorageValid();
      console.log("Token valid? ", valid);
      if (valid) {
        next("/continue-as");
      } else {
        next();
      }
    },
  },
  {
    path: "/continue-as",
    component: () => import("@/auth/views/ContinueAs.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //console.log("router.beforeEach");
  authGuard(to, from, next);
});

export default router;
