import api from "@/api/api-v1-config";
export async function getAllPurchaseOrdersAxios(status) {
  return await api.get(`purchaseorders/?status=${status}`);
}
export async function createPurchaseOrderAxios(purchaseorder) {
  return await api.post(`purchaseorders`, purchaseorder);
}
export async function updatePurchaseOrderAxios(purchaseorder) {
  return await api.put(`purchaseorders/${purchaseorder.id}`, purchaseorder);
}
export async function deletePurchaseOrderAxios(purchaseorder) {
  return await api.delete(`purchaseorders/${purchaseorder.id}`);
}
