<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">mdi-clipboard-edit-outline</v-icon>
      </template>
      <v-card>
        <form @submit.prevent="onSubmit">
          <v-card-title>
            <span class="headline">Edit Purchase Order</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    required
                    label="Order Notes"
                    v-model="bodyRequest.notes"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-switch
                    v-if="bodyRequest.status === 0"
                    v-model="openOrder"
                    label="Set as Open Order"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="blue darken-1" @click="dialog = false"
              >Cancel</v-btn
            >
            <v-btn
              text
              color="blue darken-1"
              @click="dialog = false"
              type="submit"
              >Save</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UpdatePurchaseOrderForm",

  props: {
    bodyRequest: {
      type: Object,
      required: true,
      default: () => ({
        notes: "",
        status: 0,
      }),
    },
  },

  methods: {
    ...mapActions("purchaseorderModule", [
      "updatePurchaseOrderAction",
      "removePurchaseOrderFromListAction",
    ]),
    onSubmit() {
      if (this.openOrder) {
        this.bodyRequest.status = 1;
        this.removePurchaseOrderFromListAction(this.bodyRequest);
      }
      this.updatePurchaseOrderAction(this.bodyRequest);
    },
  },
  data: () => ({
    dialog: false,
    openOrder: false,
  }),
};
</script>
