<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="green" class="mr-3" v-bind="attrs" v-on="on">
          mdi-clipboard-list-outline
        </v-icon>
      </template>
      <v-card>
        <form @submit.prevent="createPurchaseItem()">
          <v-card-title>
            <span class="headline">How many units required?</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-alert type="success">{{
                    "[" + this.bodyRequest.sku + "] " + this.bodyRequest.name
                  }}</v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    required
                    label="Quantity Required"
                    v-model="needed"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-alert dense outlined type="error" v-if="serverError">
              {{ errorMessage }}</v-alert
            >
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelDialog"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text type="submit">Save</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddSinglePurchaseItemForm",
  props: {
    bodyRequest: {
      type: Object,
      required: true,
      default: () => ({
        sku: "",
        name: "",
        id: 0,
      }),
    },
    purchaseorderId: Number,
  },
  data: () => ({
    dialog: false,
    needed: 1,
  }),
  methods: {
    ...mapActions("purchaseitemModule", [
      "createPurchaseItemAction",
      "resetServerErrorAction",
    ]),

    createPurchaseItem() {
      // console.log("inventory id: " + this.bodyRequest.id);
      // console.log("qty: " + this.bodyRequest.qty);
      // console.log("purchaseorderId: " + this.purchaseorderId);

      let payload = {
        purchaseOrderId: this.purchaseorderId,
        inventoryId: this.bodyRequest.id,
        ordered: this.needed,
      };

      this.createPurchaseItemAction(payload);

      setTimeout(() => {
        this.dialog = this.serverError ? true : false;
      }, 300);
    },

    cancelDialog() {
      this.resetServerErrorAction();
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters("purchaseitemModule", {
      serverError: "serverError",
      errorMessage: "errorMessage",
    }),
  },
};
</script>
